/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {
  function getScrollOffset() {
    var scrollOffset;
    var windowWidth = window.innerWidth;
    var adminOffset =
      $("#wpadminbar").length === 1 ? $("#wpadminbar").outerHeight() : 0;
    var isSticky = $("#sticky-wrapper").hasClass("is-sticky");
    var offset = 20;
    var navBarHeight = {
      // set explicitly as we want shrunk down heights
      xs: 62,
      sm: 86,
    };

    // add wp admin bar offset, which is only applicable at 601+
    scrollOffset = windowWidth > 600 ? adminOffset : 0;

    // add sticky nav height as header will almost always be sticky
    // when scroll to an element
    scrollOffset +=
      windowWidth < 640
        ? navBarHeight.xs + (isSticky ? 0 : 67)
        : navBarHeight.sm + (isSticky ? 0 : 122);

    // add generic offset, so target isn't right up against header
    scrollOffset += offset;

    return scrollOffset;
  }

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    common: {
      init: function () {
        // JavaScript to be fired on all pages

        // $(document).foundation(); // Foundation JavaScript

        // anchor offset to cater for sticky header, which changes the
        // Y position on the page etc... Would be good to try improve
        // this in the future
        $("body").on("click", "a.offset-anchor", function () {
          var target = $(this.hash);
          target = target.length
            ? target
            : $("[name=" + this.hash.slice(1) + "]");
          // Does a scroll target exist?
          if (target.length) {
            // Only prevent default if animation is actually gonna happen
            event.preventDefault();

            $("html, body").scrollTop(target.offset().top - getScrollOffset());
          }
        });
      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired
      },
    },
    // Home page
    home: {
      init: function () {
        // JavaScript to be fired on the home page
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
        $("#home-hero-nearest-toggle").on("click", function () {
          $("body").toggleClass("home-hero-nearest-is-open");
          return false;
        });
      },
    },
    // About us page, note the change from about-us to about_us.
    about_us: {
      init: function () {
        // JavaScript to be fired on the about us page
      },
    },
    clickandstore: {
      init: function () {},
      finalize: function () {
        // activate slider. this also exists in 'single_locations' below
        var $csSlider = $(".cs-slider").flickity({
          // options
          wrapAround: true,
          // adaptiveHeight: true,
          prevNextButtons: true,
          pageDots: false,
          imagesLoaded: true,
        });

        function resizeCsSlider() {
          $csSlider.flickity("resize");
        }

        $(function () {
          setTimeout(resizeCsSlider, 500);
          console.log("cs slider reloaded");
        });

        $(".nearest-store").on("click", function () {
          // $('html, body').animate({scrollTop : 0},800);
          $("#reveal-nearest-store").foundation("open");
          return false;
        });
      },
    },
    page_template_template_self_storage: {
      init: function () {},
      finalize: function () {
        // activate slider. this also exists in 'single_locations' below
        var $csSlider = $(".cs-slider").flickity({
          // options
          wrapAround: true,
          // adaptiveHeight: true,
          prevNextButtons: true,
          pageDots: false,
          imagesLoaded: true,
        });

        function resizeCsSlider() {
          $csSlider.flickity("resize");
        }

        $(function () {
          setTimeout(resizeCsSlider, 500);
          console.log("cs slider reloaded");
        });
      },
    },
    // reviews
    reviews: {
      init: function () {},
      finalize: function () {
        $(function () {
          $reviewsGrid = $("#reviews-all").masonry({
            transitionDuration: "0.2s",
            itemSelector: ".review__single",
          });
          function reloadReviews() {
            $reviewsGrid.masonry();
            console.log("masonry reloaded");
          }
          setTimeout(reloadReviews, 500);
        });

        var loaderHTML = "";
        $(".reviews-all").on("click", "#load-more", function (e) {
          e.preventDefault();

          $(".reviews-all").addClass("reviews-all--loading");

          $.ajax({
            type: "POST",
            url: adminAjax.adminAjaxUrl,
            data: {
              paged: $(this).attr("data-nextpage"),
              posts_per_page: $(this).attr("data-load"),
              action: "get_reviews", //this is the name of the AJAX method called in WordPress
            },
            success: function (result) {
              obj = JSON.parse(result);

              var html = "";

              $.each(obj.reviews, function (index, value) {
                html += makeReviewHtml(value);
              });

              if (obj.nextPage) {
                $("#load-more").attr("data-nextpage", obj.nextPage);
              } else {
                $("#load-more").fadeOut();
              }

              var $html = $(html);
              $("#reviews-all")
                .append($html)
                .masonry("appended", $html)
                // layout
                .masonry();

              $(".reviews-all").removeClass("reviews-all--loading");

              // console.log(obj);
            },
            error: function () {
              console.log("error");
              $(".reviews-all").removeClass("reviews-all--loading");
            },
          });
        });

        function makeReviewHtml(value) {
          html = "";
          html += '<div class="review__single review__single--centered">';
          html +=
            '<div class="review__single__top"><div class="review__single__top__inner">';
          if (value.rating) {
            html += '<div class="review__single__stars">';
            for (x = 1; x <= 5; x++) {
              if (x <= value.rating) {
                html += '<i class="icon-star" aria-hidden="true"></i>';
              } else {
                html += '<i class="icon-star-o" aria-hidden="true"></i>';
              }
            }
            html += "</div>";
          }
          if (value.title) {
            html += "<h2>‘" + value.title + "’</h2>";
          }
          if (value.review) {
            html += "<p>‘" + value.review + "’</p>";
          }
          html += "</div></div>";
          html += '<div class="review__single__bottom">';
          if (value.customer) {
            html +=
              '<span class="review__single__author">' +
              value.customer +
              "</span>";
          }
          if (value.last_updated) {
            html +=
              '<span class="review__single__date">' +
              value.last_updated +
              "</span>";
          }
          html += "</div>";
          html += "</div>";

          return html;
        }
      },
    },
    // Single store
    single_site: {
      init: function () {},
      finalize: function () {
        var adminBarHeight =
          $("#wpadminbar").length === 1 ? $("#wpadminbar").outerHeight() : 0;
        var navBarHeight = 62; // explicitly set due to sticky making it shorter
        var scrollOffset;

        scrollOffset = getOffset();

        function getOffset() {
          var windowWidth = window.innerWidth;
          return navBarHeight + (windowWidth > 600 ? adminBarHeight : 0);
        }

        // sticky
        $(".banner-store__quote-alt").sticky({
          topSpacing: scrollOffset,
        });

        // sticky needs adjusting on resize
        $(window).resize(function () {
          scrollOffset = getOffset();
          $(".banner-store__quote-alt").sticky({
            topSpacing: scrollOffset,
          });
          $(".banner-store__quote-alt").sticky("update");
        });

        // activate slider
        var $storeSlider = $(".single-store-slider").flickity({
          // options
          wrapAround: true,
          // adaptiveHeight: true,
          prevNextButtons: true,
          pageDots: false,
        });

        $(".banner-store__open-toggle").on("click", function () {
          $(this).toggleClass("active");
        });

        $(".single-store-mobile-tabs__nav").on("click", "a", function () {
          var el = $(this);
          selectTab(el);
          return false;
        });

        function selectTab(link) {
          $(".single-store-mobile-tabs__nav a").removeClass("active");
          $(".single-store-mobile-tabs__tab").removeClass("active");
          link.addClass("active");
          $(link.data("target")).addClass("active");
          $storeSlider.flickity("resize");
        }

        $(".gallery-prompt").on("click", ".gallery-prompt__link", function (e) {
          e.preventDefault();
          selectTab($(".single-store-mobile-tabs__link--gallery"));

          $("html, body").animate(
            {
              scrollTop:
                $(".single-store-mobile-tabs__nav").offset().top -
                getScrollOffset(),
            },
            1000
          );
        });

        function resizeStoreSlider() {
          $storeSlider.flickity("resize");
        }

        $(function () {
          setTimeout(resizeStoreSlider, 500);
          console.log("store slider reloaded");

          let hh_scrollbar = document.querySelector(".hh-scrollbar");
          if (hh_scrollbar) {
            // assign classes
            hh_scrollbar_classes(hh_scrollbar);

            // adjust scrollbar offset
            hh_scrollbar_adjust_offset();

            // on scroll, reassign classes
            hh_scrollbar.addEventListener("scroll", function () {
              hh_scrollbar_classes(hh_scrollbar);
            });

            // on resize, reassign classes and recheck scrollbar offset
            window.addEventListener("resize", function () {
              hh_scrollbar_classes(hh_scrollbar);
              hh_scrollbar_adjust_offset();
            });
          }
        });

        function hh_scrollbar_classes(target) {
          let max_scroll_top = target.scrollHeight - target.offsetHeight;

          // top class handler
          if (target.scrollTop === 0) target.classList.add("at-top");
          else target.classList.remove("at-top");

          // bottom class handler
          if (target.scrollTop === max_scroll_top)
            target.classList.add("at-bottom");
          else target.classList.remove("at-bottom");
        }

        function hh_scrollbar_adjust_offset(target, parent, child) {
          let scrollbar_width =
            document.querySelector(".hh-scrollbar").offsetWidth -
            document.querySelector(
              ".single-store__pricing__offerings__offering"
            ).offsetWidth;
          for (let el of document.querySelectorAll(".hh-scrollbar__more")) {
            el.style.right = scrollbar_width + "px";
          }
        }
      },
    },
    // single blog post
    single_post: {
      init: function () {},
      finalize: function () {
        $(".scrollToAuthor").on("click", function (e) {
          e.preventDefault();
          $("html, body").animate(
            {
              scrollTop: $("#hh-author").offset().top - getScrollOffset(),
            },
            1000
          );
        });
      },
    },
    single_locations: {
      init: function () {},
      finalize: function () {
        // activate slider. this also exists in 'clickandstore' above
        var $csSlider = $(".cs-slider").flickity({
          // options
          wrapAround: true,
          // adaptiveHeight: true,
          prevNextButtons: true,
          pageDots: false,
          imagesLoaded: true,
        });

        $(".nearest-store").on("click", function () {
          // $('html, body').animate({scrollTop : 0},800);
          $("#reveal-nearest-store").foundation("open");
          return false;
        });

        function resizeCsSlider() {
          $csSlider.flickity("resize");
        }

        $(function () {
          setTimeout(resizeCsSlider, 500);
        });

        const accordion2024 = document.querySelector(".accordion-area--2024");
        if (accordion2024) {
          accordion2024.addEventListener("click", (e) => {
            const target = e.target;
            if (target.classList.contains("accordion-title")) {
              target.nextElementSibling.classList.toggle("open");
            }
          });
        }
      },
    },
    page_template_template_your_quote: {
      init: function () {},
      finalize: function () {
        // check radio button when table row is clicked
        $(".your-quote-table").on("click", "tr", function (e) {
          if (!$(e.target).is("a")) {
            $(this).find(".unit-duration-radio").prop("checked", true);
          }
        });
        // set hidden field to data variable
        var bookingData = $("#henfield_additional_booking_data").data(
          "csvalue"
        );
        $("#click_and_store_option").val(bookingData);
      },
    },
    // Booking form
    booking_form: {
      init: function () {},
      finalize: function () {
        /**
         * new date selector
         */
        var now = new Date();

        // set end date based on previous availability check
        let last_availability = sessionStorage.getItem("last-availability");
        end_date = new Date(now.setDate(now.getDate() + 2 * 7));
        end_date_text = "2 weeks";

        // change end date text
        $(".end-date-threshold").text(end_date_text);

        // init date picker
        let start_date = flatpickr(".flatpickr", {
          altInput: true,
          altFormat: "j M Y",
          minDate: "today",
          maxDate: end_date.toISOString().split("T")[0],
          wrap: true,
          disableMobile: true,
        });

        /**
         * Show hide collection options
         */
        $("#collection_required").change(function () {
          var collectionRequired = $("#collection_required").val();
          if (collectionRequired == "Yes") {
            $("#collection_setting").removeClass("hide");
            checkDate();
          } else {
            $("#collection_setting").addClass("hide");
          }
        });
        $("#collection_address_type").change(function () {
          var collectionAddressType = $("#collection_address_type").val();

          if (collectionAddressType !== "same as contact address") {
            $("#collectionaddress_div").removeClass("hide");
          } else {
            $("#collectionaddress_div").addClass("hide");
          }
        });

        /**
         * if collection required already selected then check date
         */
        $("#db_start_date").change(function () {
          console.log("test");
          if (!$("#collection_setting").hasClass("hide")) {
            checkDate();
            return false;
          }
        });

        /**
         * if move-in date selected check for weekend or national holiday
         */
        function checkDate() {
          console.log("checking date");
          var dateValue = $("#db_start_date").val();
          var selectedDate = new Date(dateValue);
          var day = selectedDate.getDay();
          var bankHolidayUrl = "https://www.gov.uk/bank-holidays.json";

          $.getJSON(bankHolidayUrl, function (data) {
            $.each(
              data["england-and-wales"]["events"],
              function (index, element) {
                if (element.date === dateValue) {
                  actionReselect();
                  return false;
                }
              }
            );
          });

          if (day === 6 || day === 0) {
            actionReselect();
          }
        }

        function actionReselect() {
          //move user back to top of form
          $("html, body").animate(
            {
              scrollTop: parseInt($(".book-online__form").offset().top),
            },
            1000
          );

          // clear date input field
          start_date.clear();

          //display popup with user message
          $("#reveal-booking-form-alert").foundation("open");
        }
      },
    },
    cs_booking_form: {
      init: function () {},
      finalize: function () {
        /**
         * new date selector
         */
        var now = new Date();

        let end_date = add_months(now, 2);
        let end_date_text = "2 months";

        // change end date text
        $(".end-date-threshold").text(end_date_text);

        // init date picker
        let start_date = flatpickr(".flatpickr", {
          altInput: true,
          altFormat: "j M Y",
          minDate: "today",
          maxDate: end_date.toISOString().split("T")[0],
          wrap: true,
          disableMobile: true,
        });

        /**
         * if collection required already selected then check date
         */
        $("#db_start_date").change(function () {
          if (!$("#collection_setting").hasClass("hide")) {
            checkDate();
            return false;
          }
        });

        /**
         * Show hide collection options
         */
        $("#collection_address_type").change(function () {
          var collectionAddressType = $("#collection_address_type").val();

          if (collectionAddressType !== "same as contact address") {
            $("#collectionaddress_div").removeClass("hide");
          } else {
            $("#collectionaddress_div").addClass("hide");
          }
        });

        /**
         * if move-in date selected check for weekend or national holiday
         */
        function checkDate() {
          var dateValue = $("#db_start_date").val();
          var selectedDate = new Date(dateValue);
          var day = selectedDate.getDay();
          var bankHolidayUrl = "https://www.gov.uk/bank-holidays.json";

          $.getJSON(bankHolidayUrl, function (data) {
            $.each(
              data["england-and-wales"]["events"],
              function (index, element) {
                console.log(element.date);

                if (element.date === dateValue) {
                  actionReselect();
                  return false;
                }
              }
            );
          });

          if (day === 6 || day === 0) {
            actionReselect();
          }
        }

        function actionReselect() {
          //move user back to top of form
          $("html, body").animate(
            {
              scrollTop: parseInt($(".book-online__form").offset().top),
            },
            1000
          );

          // clear date input field
          start_date.clear();

          //display popup with user message
          $("#reveal-booking-form-alert").foundation("open");
        }
      },
    },
    // confirm your booking
    confirm_your_booking: {
      init: function () {},
      finalize: function () {
        // enable/disable button based on terms acceptance
        var reserve_button = $(".button__reserve-no-deposit");

        $("#accept_terms").on("click", function () {
          reserve_button.toggleClass("disabled", !this.checked);
        });
        // check on page load as well, in case refreshed page after checking checkbox
        reserve_button.toggleClass(
          "disabled",
          !$("#accept_terms").is(":checked")
        );

        // prevent link click if disabled
        reserve_button.on("click", function () {
          if ($(this).hasClass("disabled")) {
            return false;
          }
        });
      },
    },
    // space estimator
    space_estimator: {
      init: function () {},
      finalize: function () {
        // on the space estimator page, we add the following data attribute to
        // make the modal aware of the size selected via the space estimator
        console.log("space estimator");
        $("#reveal-nearest-store").attr("data-from-space-estimator", true);
      },
    },

    // tests - homepage - variant 20230313
    page_template_template_homepage_variant_20230313: {
      init: function () {},
      finalize: function () {},
    },
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = funcname === undefined ? "init" : funcname;
      fire = func !== "";
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === "function";

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire("common");

      // Fire page-specific init JS, and then finalize JS
      $.each(
        document.body.className.replace(/-/g, "_").split(/\s+/),
        function (i, classnm) {
          UTIL.fire(classnm);
          UTIL.fire(classnm, "finalize");
        }
      );

      // Fire common finalize JS
      UTIL.fire("common", "finalize");
    },
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

  // helper function to add months to a date
  // https://stackoverflow.com/a/2706169
  function add_months(date, months) {
    var d = date.getDate();
    date.setMonth(date.getMonth() + +months);
    if (date.getDate() != d) {
      date.setDate(0);
    }
    return date;
  }
})(jQuery); // Fully reference jQuery after this point.
